<template>
    <font-awesome-icon icon="info-circle" @click="detalhar()" size="lg" class="icon-button p-text-primary" title="Detalhar" v-if="$temAcessoView('TEMPL-02')" />
</template>

<script>
export default {
    props: {
        template: {
            type: Object,
        },
    },

    methods: {
        detalhar() {
            this.$router.push({
                name: `${this.$route.name}_Detalhar`,
                params: {
                    id: this.template.templateId,
                },
            });
        },
    },
};
</script>
